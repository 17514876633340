<template>
    <div class="view-votacao-atual">
        <div class="carregando-votacao" v-if="isLoadingVotacaoAberta">
            Procurando votação em andamento...
        </div>
        <div class="caixa-votacao-aberta" v-else-if="votacaoAberta !== null">
            <div style="font-size: 30px;margin-bottom: -80px;">
                <div class="titulo-votacao text-center">
                    <small class="d-block">{{ tituloVotacaoAberta }}</small>
                    <span class="d-block">{{ tituloProposicaoEmVotacao }}</span>
                </div>
            </div>
            <div class="text-center" style="font-size: 34px;margin-bottom: -80px;">
                <div
                    class="card-animado-votacao"
                    :class="{
                        reduzido: !isVotacaoEmAndamento,
                        'glow-aprovada': isResultadoFinalAprovada,
                        'glow-reprovada': isResultadoFinalReprovada
                    }"
                >
                    <h2 class="d-block" v-if="isVotacaoEmAndamento">
                        <i class="fas fa-vote-yea mr-3"></i>Votação Aberta
                    </h2>
                    <h2 class="d-block" v-else-if="isLoadingResultadoVotacao">
                        <i class="fas fa-hourglass mr-3"></i>Obtendo resultado
                    </h2>
                    <h2 class="d-block font-weight-bold" v-else>
                        {{ resultadoVotacao ? resultadoVotacao.statusFinal : '...' }}
                    </h2>
                    <!-- <small class="d-block">{{ textoQuantidadeVotos }}</small> -->
                </div>
            </div>
            <div class="caixa-resultados" style="font-size: 18px;margin-top: -55px;" v-if="deveMostrarResultadoVotacao">
                <div class="caixa-votos" v-if="resultadoVotacao && resultadoVotacao.votos">
                    <ul>
                        <li
                            v-for="(voto, index) of resultadoVotacao.votos"
                            :key="index"
                            :style="{ color: voto.opcao.color || 'white;text-align: right;' }"
                            :class="'row'"
                        >
                            <span :class="'col-6'" :style="'text-align: left'">{{
                                exibirApenasApelidoVereador && voto.vereador.apelido
                                    ? voto.vereador.apelido
                                    : voto.vereador.nome
                            }}</span>
                            <span :class="'col-2'">&nbsp;({{ voto.vereador.partido }})</span>
                            <strong :class="'col-2'" :style="'text-align: right !important'">{{
                                voto.opcao.nome
                            }}</strong>
                        </li>
                    </ul>
                </div>
                <div class="caixa-contagem" v-if="resultadoVotacao && resultadoVotacao.contagem">
                    <ul>
                        <li
                            v-for="(contagem, index) of resultadoVotacao.contagem"
                            :key="index"
                            :style="{ 'border-color': contagem.color }"
                        >
                            <span>{{ contagem.nome }}&nbsp;</span>
                            <span>{{ contagem.total }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="nenhuma-votacao-aberta" v-else>
            Não há votação em andamento
        </div>
    </div>
</template>

<script>
import VotacaoServiceTelao from '../domain/VotacaoServiceTelao.js';
import ParlamentarService from '../domain/ParlamentarService.js';
import AcompanhamentoWebSocketService from '../domain/AcompanhamentoWebSocketService.js';
import ProposicaoServiceTelao from '../domain/ProposicaoServiceTelao.js';

export default {
    components: {},

    props: {
        sessao: {
            type: Object,
            required: true
        },
        telaoSettings: {
            type: Object,
            required: false
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            votacaoService: new VotacaoServiceTelao(this.$dominio),
            vereadorService: new ParlamentarService(this.$dominio),
            proposicaoService: new ProposicaoServiceTelao(this.$dominio),
            isLoadingVotacaoAberta: false,
            votacaoAberta: null,
            votacaoAbertaVotos: 0,
            isLoadingVereadores: false,
            vereadores: [],
            maximoDeVotos: 0,
            proposicoes: [],
            proposicaoEmVotacaoId: null,
            isVotacaoEmAndamento: false,
            isLoadingResultadoVotacao: false,
            resultadoVotacao: null
        };
    },

    computed: {
        tituloVotacaoAberta() {
            if (this.votacaoAberta === null) return '[sem votação aberta]';
            let categoria = this.votacaoAberta.categoria.nome;
            let criterio = this.votacaoAberta.criterio_aprovacao.nome;
            let turno = `${this.votacaoAberta.turno}º Turno`;
            return `${categoria} - ${criterio} - ${turno}`;
        },
        tituloProposicaoEmVotacao() {
            if (this.proposicaoEmVotacaoId === null || this.proposicoes.length == 0) return '...';
            let proposicao = this.proposicoes[this.proposicaoEmVotacaoId];
            let categoria = proposicao.categoria.nome;
            let numero = String(proposicao.numero).padStart(4, '0');
            let ano = proposicao.ano;
            return `${categoria} Nº ${numero} de ${ano}`;
        },
        textoQuantidadeVotos() {
            return `${this.votacaoAbertaVotos} de ${this.maximoDeVotos}`;
        },
        deveMostrarResultadoVotacao() {
            return !this.isVotacaoEmAndamento && this.resultadoVotacao !== null;
        },
        isResultadoFinalAprovada() {
            let statusDesejado = 'Aprovada';
            return this.resultadoVotacao && this.resultadoVotacao.statusFinal == statusDesejado;
        },
        isResultadoFinalReprovada() {
            let statusDesejado = 'Reprovada';
            return this.resultadoVotacao && this.resultadoVotacao.statusFinal == statusDesejado;
        }
    },

    methods: {
        updateVereadores() {
            this.isLoadingVereadores = true;
            this.vereadorService
                .getCurrentLegislaturaVereadores(this.sessao.id)
                .then(response => {
                    for (let vereador of response.data.vereadores) {
                        this.vereadores[vereador.id] = vereador;
                    }
                    this.maximoDeVotos = this.vereadores.length;
                })
                .catch(() => {
                    this.vereadores = [];
                    console.error('Falha ao obter vereadores.');
                })
                .then(() => {
                    this.isLoadingVereadores = true;
                });
        },
        updateVotacaoAberta() {
            this.isLoadingVotacaoAberta = true;
            this.votacaoService
                .getAberta(this.sessao.id)
                .then(response => {
                    let votacaoObj = JSON.parse(JSON.stringify(response.data.votacao));
                    this.votacaoAberta = votacaoObj;
                    this.votacaoAbertaVotos = votacaoObj.vereadores.length || 0;
                    this.proposicaoEmVotacaoId = votacaoObj.proposicao_id || null;
                    this.isVotacaoEmAndamento = true;
                    this.isLoadingResultadoVotacao = false;
                    this.resultadoVotacao = null;
                })
                .catch(error => {
                    if (error.response.status == 404) {
                        this.votacaoAberta = null;
                    } else {
                        console.error('Não foi possível obter votação aberta da sessão. Exception.');
                    }
                })
                .then(() => {
                    this.isLoadingVotacaoAberta = false;
                });
        },
        updateProposicoes() {
            this.proposicaoService
                .getProposicoes(this.sessao.id)
                .then(response => {
                    for (let proposicao of response.data.proposicoes) {
                        this.proposicoes[proposicao.id] = proposicao;
                    }
                })
                .catch(() => {
                    this.proposicoes = [];
                });
        },
        updateResultadoVotacao() {
            this.isLoadingResultadoVotacao = true;
            this.votacaoService
                .getResultado(this.votacaoAberta.id)
                .then(response => {
                    let responseObj = JSON.parse(JSON.stringify(response.data));
                    this.resultadoVotacao = responseObj;
                })
                .catch(() => {
                    console.error('Não foi possível obter resultados de votação.');
                })
                .then(() => {
                    this.isLoadingResultadoVotacao = false;
                });
        },
        updateResultadoVotacaoUnanime(socketData) {
            this.resultadoVotacao = null;
            this.isLoadingResultadoVotacao = true;
            this.proposicaoEmVotacaoId = socketData.votacao.proposicao_id || null;
            this.isLoadingVotacaoAberta = false;
            this.votacaoAberta = socketData.votacao;
            this.isVotacaoEmAndamento = false;
            this.votacaoAbertaVotos = socketData.votacao.vereadores.length || 0;

            this.votacaoService
                .getResultado(this.votacaoAberta.id)
                .then(response => {
                    let responseObj = JSON.parse(JSON.stringify(response.data));
                    this.resultadoVotacao = responseObj;
                    console.log('resultado votacao', responseObj);
                })
                .catch(() => {
                    console.error('Não foi possível obter resultados de votação.');
                })
                .then(() => {
                    this.isLoadingResultadoVotacao = false;
                    this.votacaoAberta.id = null;
                });
        },
        handleSocketVotacaoIniciada(socketData) {
            this.updateVotacaoAberta();
            this.proposicaoEmVotacaoId = socketData.proposicaoId || null;
        },
        handleSocketVotacaoEncerrada(socketData) {
            let votacaoAbertaId = this.votacaoAberta !== null ? this.votacaoAberta.id : null;
            if (!votacaoAbertaId) {
                this.updateResultadoVotacaoUnanime(socketData);
                return;
            }
            let votacaoEncerradaId = socketData.votacaoId;
            if (!votacaoAbertaId || votacaoAbertaId != votacaoEncerradaId) return;
            this.isVotacaoEmAndamento = false;
            this.updateResultadoVotacao();
        },
        handleSocketVereadorVotou(socketData) {
            console.log('Vereador Votou', socketData);
            /*
            VereadorVotou (vereadorId, votacaoId, votacaoOpcaoId)
            */
        }
    },

    created() {
        let channelName = new AcompanhamentoWebSocketService(this.$dominio, this.sessao.id).getChannelName();
        let namespacePrefix = '.App\\Events\\AcompanhamentoSessao\\';
        window.Echo.channel(channelName)
            .listen(namespacePrefix + 'VotacaoDeProposicaoIniciada', socketData => {
                this.handleSocketVotacaoIniciada(socketData);
            })
            .listen(namespacePrefix + 'VotacaoDeProposicaoEncerrada', socketData => {
                this.handleSocketVotacaoEncerrada(socketData);
            })
            .listen(namespacePrefix + 'VereadorVotou', socketData => {
                this.handleSocketVereadorVotou(socketData);
            });

        this.updateProposicoes();
        this.updateVereadores();
        this.updateVotacaoAberta();
    }
};
</script>

<style lang="scss" scoped>
.view-votacao-atual {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    align-items: stretch;
}

.nenhuma-votacao-aberta,
.carregando-votacao {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    font-size: 25px;
    @media (min-width: 640px) {
        font-size: 30px;
    }
    @media (min-width: 768px) {
        font-size: 35px;
    }
    @media (min-width: 1024px) {
        font-size: 40px;
    }
    @media (min-width: 1280px) {
        font-size: 46px;
    }
    @media (min-width: 1536px) {
        font-size: 50px;
    }
    @media (min-width: 2000px) {
        font-size: 55px;
    }
    @media (min-width: 2400px) {
        font-size: 57px;
    }
    @media (min-width: 2800px) {
        font-size: 60px;
    }
    @media (min-width: 3200px) {
        font-size: 65px;
    }
    @media (min-width: 3600px) {
        font-size: 70px;
    }
    @media (min-width: 4000px) {
        font-size: 76px;
    }
}

.caixa-votacao-aberta {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
}

.titulo-votacao {
    color: white;
    font-size: 1em;
    > small {
        font-size: 0.55em;
    }
    @media (min-width: 640px) {
        font-size: 1.1em;
    }
    @media (min-width: 768px) {
        font-size: 1.2em;
    }
    @media (min-width: 1024px) {
        font-size: 1.3em;
    }
    @media (min-width: 1280px) {
        font-size: 1.4em;
    }
    @media (min-width: 1536px) {
        font-size: 1.5em;
    }
    @media (min-width: 2000px) {
        font-size: 1.8em;
    }
    @media (min-width: 2400px) {
        font-size: 2em;
    }
    @media (min-width: 2800px) {
        font-size: 2.3em;
    }
    @media (min-width: 3200px) {
        font-size: 2.6em;
    }
    @media (min-width: 3600px) {
        font-size: 2.9em;
    }
    @media (min-width: 4000px) {
        font-size: 3.2em;
    }
}

.card-animado-votacao {
    margin-top: 0.4em;
    margin-bottom: 0.1em;
    padding: 0.4em 1.3em 0.3em 1.3em;
    text-align: center;
    color: rgb(45, 96, 138);
    background: rgba(255, 255, 255, 1);
    > i,
    > h2 {
        font-size: 1em;
    }
    > small {
        font-size: 0.6em;
    }
    font-size: 1em;
    @media (min-width: 640px) {
        font-size: 1.1em;
    }
    @media (min-width: 768px) {
        font-size: 1.2em;
    }
    @media (min-width: 1024px) {
        font-size: 1.3em;
    }
    @media (min-width: 1280px) {
        font-size: 1.4em;
    }
    @media (min-width: 1536px) {
        font-size: 1.5em;
    }
    @media (min-width: 2000px) {
        font-size: 1.8em;
    }
    @media (min-width: 2400px) {
        font-size: 2em;
    }
    @media (min-width: 2800px) {
        font-size: 2.3em;
    }
    @media (min-width: 3200px) {
        font-size: 2.6em;
    }
    @media (min-width: 3600px) {
        font-size: 2.9em;
    }
    @media (min-width: 4000px) {
        font-size: 3.2em;
    }
}

.card-animado-votacao.reduzido {
    margin-top: 0.1em;
    padding: 0.2em 0.6em 0.1em 0.6em;
    > i,
    > h2 {
        font-size: 0.7em;
    }
}

.glow-aprovada {
    box-shadow: 0 0 0.2em 0 rgb(36, 223, 15);
    background-color: rgb(7, 168, 7);
    color: rgba(255, 255, 255, 0.9);
}

.glow-reprovada {
    box-shadow: 0 0 0.2em 0 rgb(255, 42, 42);
    background-color: rgb(199, 25, 25);
    color: rgba(255, 255, 255, 0.9);
}

.caixa-resultados .caixa-contagem {
    background: white;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    > ul {
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        > li {
            display: inline-block;
            color: black;
            border-top: 0.12em solid transparent;
            border-bottom: 0.25em solid transparent;
            padding: 0.3em 0.5em;
            font-size: 1em;
            font-weight: bold;
            text-align: center;
            filter: saturate(2);
        }
    }
    font-size: 1em;
    @media (min-width: 640px) {
        font-size: 1.1em;
    }
    @media (min-width: 768px) {
        font-size: 1.2em;
    }
    @media (min-width: 1024px) {
        font-size: 1.3em;
    }
    @media (min-width: 1280px) {
        font-size: 1.4em;
    }
    @media (min-width: 1536px) {
        font-size: 1.5em;
    }
    @media (min-width: 2000px) {
        font-size: 1.8em;
    }
    @media (min-width: 2400px) {
        font-size: 2em;
    }
    @media (min-width: 2800px) {
        font-size: 2.3em;
    }
    @media (min-width: 3200px) {
        font-size: 2.6em;
    }
    @media (min-width: 3600px) {
        font-size: 2.9em;
    }
    @media (min-width: 4000px) {
        font-size: 3.2em;
    }
}

.caixa-resultados .caixa-votos {
    > ul {
        padding: 0em;
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        margin: 0;
        > li {
            font-size: 1.25em;
            width: 50%;
            margin: 0.15em 0;
            color: rgba(255, 255, 255, 0.9);
            line-height: 1;
            padding: 0.3em;
            filter: saturate(2);
            > span {
                flex-grow: 1;
                margin-right: 1.5em;
            }
        }
    }
    font-size: 0.8em;
    @media (min-width: 640px) {
        font-size: 0.9em;
    }
    @media (min-width: 768px) {
        font-size: 1em;
    }
    @media (min-width: 1024px) {
        font-size: 1.1em;
    }
    @media (min-width: 1280px) {
        font-size: 1.2em;
    }
    @media (min-width: 1536px) {
        font-size: 1.3em;
    }
    @media (min-width: 2000px) {
        font-size: 1.4em;
    }
    @media (min-width: 2400px) {
        font-size: 1.6em;
    }
    @media (min-width: 2800px) {
        font-size: 1.8em;
    }
    @media (min-width: 3200px) {
        font-size: 2em;
    }
    @media (min-width: 3600px) {
        font-size: 2.2em;
    }
    @media (min-width: 4000px) {
        font-size: 2.4em;
    }
}

/* LEMBRETE: Elementos que tem style embutido, serão trocados por tamanhos customizáveis, ver view ComPalavra */
</style>
